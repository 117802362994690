<template>
  <div class="kt-container pt-3">
    <h3 class="text-center">Try other apps</h3>
    <div class="row">
      <div class="col-12 col-sm mb-3">
        <div class="card card-custom ml-auto" style="max-width: 500px">
          <div class="card-header p-0">
            <img class="w-100" src="../assets/images/fastcheckout.jpg">
          </div>
          <div class="card-body">
            <h3>Fast Checkout In One Click</h3>
            <p>Free plan available</p>
            <h6>Skip cart page and go to direct to checkout!</h6>
            <a class="btn btn-primary" href="https://apps.shopify.com/fast-checkout-in-one-click" target="_blank">Try
              it for FREE</a>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm mb-3">
        <div class="card card-custom mr-auto" style="max-width: 500px">
          <div class="card-header p-0">
            <img class="w-100" src="../assets/images/shipping.jpg">
          </div>
          <div class="card-body">
            <h3>Estimated Shipping Cost</h3>
            <p>Free plan available</p>
            <h6>Show delivery cost by users ip and your store shipping rates</h6>
            <a class="btn btn-primary" href="https://apps.shopify.com/estimated-shipping-rates" target="_blank">Try
              it for FREE</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurApps"
}
</script>

<style scoped>

</style>