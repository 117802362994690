<template>
  <div
      id="kt_header"
      :class="
      `kt-header kt-grid__item  kt-header--fixed kt-aside--enabled d-flex justify-content-end px-4`
    "
  >
    <div
        id="kt_header_mobile"
        class="kt-header-mobile  kt-header-mobile--fixed "
    >
      <div class="kt-header-mobile__toolbar w-100 justify-content-between">
        <button @click="settings.onClick" class="btn btn-primary">{{settings.title}}</button>

        <button
            id="kt_aside_mobile_toggler"
            :class="
            `kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left ${is_open_aside &&
              'kt-header-mobile__toolbar-topbar-toggler--active'}`
          "
            @click="toggleAside(!is_open_aside)"
        >
          <span></span>
        </button>
        <b-dropdown id="dropdown-1" :text="shopName" class="text-white" variant="link">
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="d-none d-lg-flex align-items-center">
      <button @click="settings.onClick" class="btn btn-primary btn-sm">{{settings.title}}</button>
      <b-dropdown id="dropdown-1" :text="shopName" variant="link">
        <b-dropdown-item @click="logout">Logout</b-dropdown-item>
      </b-dropdown>
    </div>

  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from "vuex";
import {ON_TOGGLE_ASIDE} from '../store/modules/layout/types';
import {LOGOUT} from '../store/modules/auth/types';

export default {
  name: "header-menu",
  props: {
    showAside: Boolean,
  },
  computed: {
    ...mapState({
      shopName: state => state.shop.shop.name,
      is_open_aside: state => state.layout.is_open_aside,
      settings: state => state.layout.settings
    }),
  },
  methods: {
    ...mapMutations({
      toggleAside: `layout/${ON_TOGGLE_ASIDE}`,
    }),
    ...mapActions({
      onLogout: `auth/${LOGOUT}`,
    }),
    onOpenPopover: function () {
      this.$refs.profile.focus();
    },
    logout: function () {
      this.onLogout();
    }
  }
}
</script>


