<template>
  <div
      class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid d-flex flex-column"
      id="kt_aside_menu_wrapper"
  >
    <div id="kt_aside_menu" class="kt-aside-menu  kt-aside-menu--dropdown ">
      <ul class="kt-menu__nav ">
        <li
            title="tariffs"
            :class="
            `m-0 kt-menu__item ${checkActiveItem('guides')}`
          "
            aria-haspopup="true"
        >
          <router-link
              :to="{ name: 'guides' }"
              :class="`kt-menu__link m-0`"
              id="guides"
          >
            <span class="kt-menu__link-icon"><i class="fas fa-table"></i></span>
            <span class="kt-menu__link-text">Guides</span>
          </router-link>
        </li>
        <li
            title="settings"
            :class="
            `m-0 kt-menu__item  ${checkActiveItem('settings')}`
          "
            aria-haspopup="true"
        >
          <router-link
              :to="{ name: 'settings' }"
              :class="`kt-menu__link m-0`"
              id="settings"
          >
            <span class="kt-menu__link-icon">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                   height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path
                        d="M7,3 L17,3 C19.209139,3 21,4.790861 21,7 C21,9.209139 19.209139,11 17,11 L7,11 C4.790861,11 3,9.209139 3,7 C3,4.790861 4.790861,3 7,3 Z M7,9 C8.1045695,9 9,8.1045695 9,7 C9,5.8954305 8.1045695,5 7,5 C5.8954305,5 5,5.8954305 5,7 C5,8.1045695 5.8954305,9 7,9 Z"
                        fill="#000000"/>
                    <path
                        d="M7,13 L17,13 C19.209139,13 21,14.790861 21,17 C21,19.209139 19.209139,21 17,21 L7,21 C4.790861,21 3,19.209139 3,17 C3,14.790861 4.790861,13 7,13 Z M17,19 C18.1045695,19 19,18.1045695 19,17 C19,15.8954305 18.1045695,15 17,15 C15.8954305,15 15,15.8954305 15,17 C15,18.1045695 15.8954305,19 17,19 Z"
                        fill="#000000" opacity="0.3"/>
                </g>
              </svg>
            </span>
            <span class="kt-menu__link-text">Settings</span>
          </router-link>
        </li>
        <li
            title="tariffs"
            :class="
            `m-0 kt-menu__item ${checkActiveItem('tariffs')}`
          "
            aria-haspopup="true"
        >
          <router-link
              :to="{ name: 'tariffs' }"
              :class="`kt-menu__link m-0`"
              id="tariff"
          >
            <span class="kt-menu__link-icon"><i class="fas fa-file-invoice-dollar"></i></span>
            <span class="kt-menu__link-text">Tariffs</span>
          </router-link>
        </li>
        <li
            title="FAQ"
            :class="
            `m-0 kt-menu__item ${checkActiveItem('faq')}`
          "
            aria-haspopup="true"
        >
          <router-link
              :to="{ name: 'faq' }"
              :class="`kt-menu__link m-0`"
              id="faq"
          >
            <span class="kt-menu__link-icon"><i class="far fa-question-circle"></i></span>
            <span class="kt-menu__link-text">FAQ</span>
          </router-link>
        </li>
        <li
            title="Privacy policy"
            :class="
            `m-0 kt-menu__item ${checkActiveItem('policy')}`
          "
            aria-haspopup="true"
        >
          <router-link
              :to="{ name: 'policy' }"
              :class="`kt-menu__link m-0`"
              id="faq"
          >
            <span class="kt-menu__link-icon"><i class="fas fa-user-shield"></i></span>
            <span class="kt-menu__link-text">Privacy policy</span>
          </router-link>
        </li>
        <li
            title="Our apps"
            :class="
            `m-0 kt-menu__item`
          "
            aria-haspopup="true"
        >
          <a target="_blank" href="https://apps.shopify.com/partners/sweet-ecom"
             :class="`kt-menu__link m-0`"
          >
            <span class="kt-menu__link-icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="30" height="30"><path d="M22.84 2.035c-3.18-.087-6.514 4.156-8.31 10.686l-5.645 1.84c-.367.12-.634.439-.684.822l-3.445 26.81a1 1 0 0 0 .806 1.11l24.069 4.58 1.857-40.686-1.111.362c-.663-2.444-1.903-3.981-3.537-4.33a3.096 3.096 0 0 0-1.438.03c-.557-.587-1.198-.993-1.93-1.15a3.473 3.473 0 0 0-.632-.074zm-.12 2.002a1.47 1.47 0 0 1 .337.03c.17.036.336.112.496.214-1.985 1.697-3.503 4.918-4.305 6.905l-2.4.78c1.635-5.024 4.145-7.889 5.873-7.929zm3.936 1.23c.768.318 1.416 1.35 1.82 2.91l-1.023.335c-.16-1.238-.424-2.334-.797-3.244zm-1.9.651c.365.849.63 1.948.765 3.223l-3.73 1.214c1.013-2.15 2.057-3.625 2.965-4.437zm8.726 1.478-1.845 40.41 12.58-2.783a1 1 0 0 0 .773-1.119 38536.18 38536.18 0 0 1-4.695-32.62 1.017 1.017 0 0 0-.076-.292 1.682 1.682 0 0 0-1.024-.935 1.123 1.123 0 0 0-.299-.053c-.222-.01-2.194-.163-3.033-.229-.686-.7-2.002-2.042-2.345-2.351-.011-.011-.025-.018-.036-.028zM22.72 18.023c2.064 0 3.162.65 3.209.678a.5.5 0 0 1 .215.58l-1.512 4.66a.498.498 0 0 1-.29.311.496.496 0 0 1-.425-.023c-.013-.007-1.286-.69-2.76-.69-1.783 0-2.049.912-2.049 1.455 0 .59.773 1.173 1.67 1.846 1.587 1.192 3.76 2.823 3.76 6.1 0 4.103-2.555 6.972-6.213 6.972-4.16 0-6.255-2.591-6.342-2.701a.502.502 0 0 1-.087-.453l1.062-3.572a.505.505 0 0 1 .813-.235c.02.018 1.957 1.711 3.492 1.711.893 0 1.129-.716 1.129-1.094 0-.98-.717-1.577-1.625-2.332-1.254-1.042-2.813-2.339-2.813-5.154 0-3.881 2.706-8.057 8.647-8.057l.119-.002z"/></svg>
            </span>
            <span class="kt-menu__link-text">Our apps</span>
          </a>
        </li>
        <li
            title="Our apps"
            :class="
            `m-0 kt-menu__item`
          "
            aria-haspopup="true"
        >
          <a target="_blank" href="https://www.facebook.com/sweetecom/"
             :class="`kt-menu__link m-0`"
          >
            <span class="kt-menu__link-icon"><i class="fab fa-facebook-square"></i></span>
            <span class="kt-menu__link-text">Join us on Facebook</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="mt-auto text-center">SweetEcom © {{ currentYear }}</div>
  </div>
</template>
<script>

export default {
  name: "AsideMenu",
  computed: {
    currentYear() {
      return (new Date).getFullYear();
    }
  },
  methods: {
    checkActiveItem(page) {
      return (this.$route.name === page || this.$route.path.includes(page) ) ? "kt-menu__item--active" : '';
    }
  }
};
</script>
<style scoped></style>
